import formatRelativeDateTime from '@lyra/core/utils/formatRelativeDateTime'
import { Notification } from '@lyra/web/constants/notifications'
import {
  getNotificationDescription,
  getNotificationIsReverted,
  getNotificationPath,
  getNotificationTitle,
} from '@lyra/web/utils/notifications'
import { useRouter } from 'next/navigation'
import React from 'react'

import NavDropdownButton from '../../../../components/common/NavDropdownButton'
import NotificationIcon from '../../../../components/common/NotificationIcon'

type Props = {
  notification: Notification
}

const NavNotificationListItem = ({ notification }: Props) => {
  const router = useRouter()
  return (
    <NavDropdownButton.ListItem
      icon={<NotificationIcon notification={notification} />}
      iconColor={getNotificationIsReverted(notification) ? 'red' : undefined}
      label={getNotificationTitle(notification)}
      sublabel={getNotificationDescription(notification)}
      rightLabel={formatRelativeDateTime(new Date(notification.timestamp))}
      isHighlighted={!notification.isSeen}
      isRegularText
      onPress={() => router.push(getNotificationPath(notification))}
    />
  )
}

export default NavNotificationListItem
