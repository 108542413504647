'use client'
import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import { ArrowUpRight } from '@lyra/core/components/Icon'
import Modal from '@lyra/core/components/Modal'
import Section from '@lyra/core/components/Section'
import useIsMobile from '@lyra/core/hooks/useIsMobile'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'

import { isMainnet, isPreview, isProd } from '../constants/env'
import { LOCAL_STORAGE_DERIVE_REBRAND_POPUP_KEY } from '../constants/localStorage'
import { BLOG_DERIVE_REBRAND_URL } from '../constants/urls'
import OptionsTradeForm from '../containers/trade/OptionsTradeForm'
import useBoolLocalStorage from '../hooks/local_storage/useBoolLocalStorage'
import useSelectedOptions from '../hooks/useSelectedOptions'

export default function GlobalModalHelper() {
  const { selectedOptions, clearOptions } = useSelectedOptions()
  const isMobile = useIsMobile()

  const [hasSeenDerivePopup, setHasSeenDerivePopup] = useBoolLocalStorage(
    LOCAL_STORAGE_DERIVE_REBRAND_POPUP_KEY
  )
  const [isDerivePopupOpen, setIsDerivePopupOpen] = useState(false)
  useEffect(() => {
    if (!hasSeenDerivePopup && !isDerivePopupOpen && isProd && !isPreview && isMainnet) {
      setTimeout(() => {
        setIsDerivePopupOpen(true)
        setHasSeenDerivePopup(true)
      }, 2000)
    }
  }, [hasSeenDerivePopup, isDerivePopupOpen, setHasSeenDerivePopup])

  return (
    <>
      {/* Derive rebrand popup */}
      {isDerivePopupOpen ? (
        <Modal
          onClose={() => {
            setIsDerivePopupOpen(false)
          }}
          title="Lyra is now Derive"
          disableClose
          overflow="hidden"
        >
          <Section.XStack height={160} justifyContent="center" alignItems="center" width="100%">
            <Image
              alt="logo-animated"
              unoptimized
              src="/images/logo-animating-noloop.gif"
              width={350}
              height={350}
            />
          </Section.XStack>
          <Section.YStack>
            <BodyText size="lg" color="secondary">
              Yesterday, we were Lyra.
            </BodyText>
            <BodyText size="lg" color="secondary">
              Today, we are Derive.
            </BodyText>
            <BodyText size="lg" color="secondary">
              Tomorrow, derivatives change forever.
            </BodyText>
          </Section.YStack>
          <Section.YStack>
            <Button
              href={BLOG_DERIVE_REBRAND_URL}
              target="_blank"
              size="lg"
              width="100%"
              label="Learn More"
              color="cta"
              icon={<ArrowUpRight />}
            />
            <Button
              size="lg"
              width="100%"
              label="Dismiss"
              onPress={() => setIsDerivePopupOpen(false)}
            />
          </Section.YStack>
        </Modal>
      ) : null}
      {/* Trade poup */}
      {/* TODO: @earthtojake move this into trade CTAs */}
      <Modal
        title={null}
        isOpen={isMobile && selectedOptions.length > 0}
        onChangeOpen={(isOpen) => {
          if (!isOpen) {
            clearOptions()
          }
        }}
      >
        {/* TODO: @earthtojake nested sections here, fix */}
        <OptionsTradeForm />
      </Modal>
    </>
  )
}
