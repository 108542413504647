import isServer from '@lyra/core/utils/isServer'
import NextVideoLink from '@lyra/web/components/common/NextVideoLink'
import useBoolLocalStorage from '@lyra/web/hooks/local_storage/useBoolLocalStorage'
import useSelectedTab from '@lyra/web/hooks/useSelectedTab'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { getTabPageIdForPath, TabDetails, TabId, TabPage } from '../../../../utils/tabs'

const NavDesktopTabPageLink = ({ page }: { page: TabPage }) => {
  const pathname = usePathname()
  const activePage = useMemo(() => getTabPageIdForPath(pathname), [pathname])

  return (
    <NextVideoLink
      key={page.name}
      href={page.path}
      label={page.name}
      isActive={activePage === page.id}
      size="md"
    />
  )
}

const NavDesktopHeaderTabLink = ({ tab, usePages }: { tab: TabDetails; usePages: boolean }) => {
  const selectedTab = useSelectedTab()

  const [isBorrowOpened, setIsBorrowOpened] = useBoolLocalStorage('borrow-tab-nudge')
  const showBorrowNudge = !isServer && !isBorrowOpened && selectedTab?.id !== TabId.Airdrop

  return usePages ? (
    <>
      {tab.pages.map((page) => (
        <NavDesktopTabPageLink page={page} key={page.name} />
      ))}
    </>
  ) : (
    <NextVideoLink
      key={tab.id}
      href={tab.pages[0].path}
      label={tab.name}
      isActive={selectedTab && selectedTab.id === tab.id}
      size="md"
      onPress={() => {
        if (tab.id === TabId.Borrow) {
          setIsBorrowOpened(true)
        }
      }}
      showNudge={tab.id === TabId.Borrow && showBorrowNudge}
    />
  )
}

export default NavDesktopHeaderTabLink
