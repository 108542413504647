'use client'

import { datadogRum } from '@datadog/browser-rum'
import { YStack } from '@lyra/core/components'
import isServer from '@lyra/core/utils/isServer'
import { usePrivy } from '@privy-io/react-auth'
import spindl from '@spindl-xyz/attribution'
import { usePathname } from 'next/navigation'
import React, { useEffect, useRef } from 'react'

import { env, isMainnet } from '../constants/env'
import {
  NAV_HEIGHT_DESKTOP,
  NAV_MARQUEE_HEIGHT,
  NAV_MOBILE_HEIGHT,
  NAV_MOBILE_TABS_HEIGHT,
} from '../constants/layout'
import Nav from '../containers/common/Nav'
import useAuth from '../hooks/useAuth'
import usePassword from '../hooks/usePassword'
import { beforeSendFilter } from '../utils/logger'
import { removeQueryAndHashFromPathname } from '../utils/pages'
import sleep from '../utils/sleep'
import PasswordPageHelper from './auth/PasswordPageHelper'
import LoadingPageHelper from './LoadingPageHelper'

if (
  isMainnet &&
  ['production', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV ?? '') &&
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DATADOG_APP_ID
) {
  const orderbookApiUrl = process.env.NEXT_PUBLIC_ORDERBOOK_API_URL
  try {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'client',
      env: process.env.NEXT_PUBLIC_VERCEL_ENV,
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? 'local',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: orderbookApiUrl ? [(url) => url.startsWith(orderbookApiUrl)] : undefined,
      beforeSend: beforeSendFilter,
    })
    console.debug('Datadog intialized')
  } catch (error) {
    console.debug('Something went wrong intializing Datadog')
  }
}

// Initialize Spindl
try {
  if (process.env.NEXT_PUBLIC_SPINDL_API_KEY && !isServer) {
    spindl.configure({ sdkKey: process.env.NEXT_PUBLIC_SPINDL_API_KEY })
    console.debug('Spindl initialized')
  }
} catch (error) {
  console.debug('Something went wrong initializing Spindl')
}

type Props = {
  children?: React.ReactNode
}

export default function AppLayoutHelper({ children }: Props) {
  const { isPageLocked } = usePassword()
  const { user, isMaybeAuthenticated, mutate, logout } = useAuth()
  const pathname = usePathname()
  const cleanedPathname = removeQueryAndHashFromPathname(pathname)

  // only log pageView when cleanedPathname updates
  useEffect(() => {
    // Log page views to Spindl
    if (process.env.NEXT_PUBLIC_SPINDL_API_KEY) {
      try {
        spindl.configure({ sdkKey: process.env.NEXT_PUBLIC_SPINDL_API_KEY })
        spindl.pageView()
      } catch (err) {
        console.warn('Spindl pageView failed')
      }
    }
  }, [cleanedPathname])

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.address,
        ownerAddress: user.ownerAddress,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.address])

  const { getAccessToken } = usePrivy()

  const isRefreshingToken = useRef(false)
  useEffect(() => {
    // SUPER IMPORTANT!!
    // this function must always end with isMaybeAuthenticated = false
    async function refreshSession() {
      try {
        isRefreshingToken.current = true

        // https://docs.privy.io/guide/react/authorization#managing-expired-access-tokens
        let accessToken: string | null = null
        let attempts = 6
        while (!accessToken && attempts > 0) {
          accessToken = await getAccessToken()
          attempts--
          console.debug('poll access token', { accessToken, attempts })
          // 500ms * 6 attempts, 3s total delay
          await sleep(500)
        }

        if (accessToken) {
          // reauthenticate user
          const auth = await mutate(accessToken)
          console.debug('privy: refreshed auth token', {
            isAuthenticated: auth.isAuthenticated,
            isMaybeAuthenticated: auth.isMaybeAuthenticated,
          })
          if (auth.isMaybeAuthenticated) {
            // auth failed, user is still isMaybeAuthenticated, logout
            console.warn('privy: not authenticated, logging out to avoid blocking')
            await logout()
          }
        } else {
          // logout user
          console.debug('privy: no auth token, logout')
          await logout()
        }
      } catch (error) {
        // logout user
        console.debug('privy: failed to refresh auth token')
        console.error(error)
        await logout()
      } finally {
        isRefreshingToken.current = false
      }
    }

    if (isMaybeAuthenticated && !isRefreshingToken.current) {
      console.debug('privy: refreshing auth token...')
      refreshSession()
    }
  }, [isMaybeAuthenticated, getAccessToken, mutate, logout])

  const showPagePadding = !isPageLocked && !isMaybeAuthenticated

  useEffect(() => {
    console.debug('env:', {
      vercel: process.env.NEXT_PUBLIC_VERCEL_ENV,
      app: env,
    })
  }, [])

  return (
    <YStack
      // Warning: #root enforces css for page heights, do not remove
      id="root"
      alignItems="center"
      $mobile={{
        paddingTop: showPagePadding ? NAV_MOBILE_HEIGHT : 0,
        paddingBottom: showPagePadding ? NAV_MOBILE_TABS_HEIGHT : 0,
      }}
      $desktop={{
        paddingTop: showPagePadding ? NAV_HEIGHT_DESKTOP : 0,
        paddingBottom: showPagePadding ? NAV_MARQUEE_HEIGHT : 0,
      }}
    >
      {isPageLocked ? (
        <PasswordPageHelper />
      ) : isMaybeAuthenticated ? (
        <YStack alignItems="center">
          <LoadingPageHelper />
        </YStack>
      ) : (
        <>
          <Nav />
          {children}
        </>
      )}
    </YStack>
  )
}
