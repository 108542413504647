import tryRequest, { RequestOptions } from './tryRequest'
import {
  PublicValidateInviteCodeParamsSchema,
  PublicValidateInviteCodeResponseSchema,
} from './types/public.validate_invite_code'

export default async function fetchValidateInviteCode(code: string, options?: RequestOptions) {
  return tryRequest<PublicValidateInviteCodeParamsSchema, PublicValidateInviteCodeResponseSchema>(
    '/public/validate_invite_code',
    { code },
    options
  )
}
