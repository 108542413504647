import { XStack, YStack } from '@lyra/core/components'
import Icon, { DeriveLogo } from '@lyra/core/components/Icon'
import VideoText from '@lyra/core/components/VideoText'
import { isTestnet } from '@lyra/web/constants/env'
import { NAV_HEIGHT_DESKTOP } from '@lyra/web/constants/layout'
import { PageId } from '@lyra/web/constants/pages'
import useAuth from '@lyra/web/hooks/useAuth'
import useRenderTabs from '@lyra/web/hooks/useRenderTabs'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getPagePath } from '@lyra/web/utils/pages'
import { TabId } from '@lyra/web/utils/tabs'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'

import DepositButton from '../../DepositButton'
import NavAccountDropdownButton from '../NavAccountDropdownButton'
import NavDevToolsDropdownButton from '../NavDevToolsDropdownButton'
import NavNotificationDropdownButton from '../NavNotificationDropdownButton'
import NavDesktopHeaderMoreLink from './NavDesktopHeaderMoreLink'
import NavDesktopHeaderTabLink from './NavDesktopHeaderTabLink'

const DeriveLogoAnimating = () => {
  const router = useRouter()

  const [playGif, setPlayGif] = useState(false)

  const [gifSrc, setGifSrc] = useState<string>()

  useEffect(() => {
    const gif = new window.Image()
    gif.src = '/images/logo-animating.gif'
    gif.onload = () => {
      setGifSrc(new URL(gif.src).pathname)
    }
  }, [])

  return (
    <XStack
      width={24}
      height={24}
      onPress={() => router.push(getPagePath({ page: PageId.Landing }))}
      onHoverIn={() => {
        if (playGif) {
          return
        }
        setPlayGif(true)
        setTimeout(() => setPlayGif(false), 2500)
      }}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
    >
      {gifSrc && playGif ? (
        <Image
          style={{ position: 'absolute' }}
          alt="logo-animating"
          width={60}
          height={60}
          unoptimized
          src={gifSrc}
        />
      ) : (
        <Icon icon={<DeriveLogo />} size={24} />
      )}
    </XStack>
  )
}

export default function NavDesktopHeader() {
  const { isAuthenticated } = useAuth()
  const { pendingBridges } = useTransaction()

  const tabs = useRenderTabs(false)

  return (
    <YStack
      width="100%"
      backgroundColor="$appBg"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      zIndex={10}
      borderBottomWidth={1}
      borderColor="$hairline"
    >
      <XStack
        padding="$3"
        width="100%"
        height={NAV_HEIGHT_DESKTOP}
        alignItems="center"
        justifyContent="space-between"
      >
        <XStack gap="$3" alignItems="center">
          <DeriveLogoAnimating />
          <XStack gap="$3" zIndex={11}>
            {isTestnet ? <VideoText color="amber">TESTNET</VideoText> : null}
            {tabs.map((tab) => (
              // render all trade pages as tabs
              <NavDesktopHeaderTabLink key={tab.id} tab={tab} usePages={tab.id === TabId.Trade} />
            ))}
            <NavDesktopHeaderMoreLink />
          </XStack>
        </XStack>
        <XStack marginLeft="auto" alignItems="center" gap="$2" height="100%">
          <NavDevToolsDropdownButton />
          {isAuthenticated ? (
            <DepositButton isCta={false} showPending={!!pendingBridges.length} />
          ) : null}
          {isAuthenticated ? <NavNotificationDropdownButton /> : null}
          <NavAccountDropdownButton />
        </XStack>
      </XStack>
    </YStack>
  )
}
