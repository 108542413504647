import Icon, { DeriveLogo } from '@lyra/core/components/Icon'
import Spinner from '@lyra/core/components/Spinner'
import NextLinkButton from '@lyra/web/components/common/NextLinkButton'
import { HistoryTab } from '@lyra/web/constants/history'
import { NAV_MOBILE_HEIGHT } from '@lyra/web/constants/layout'
import { PageId } from '@lyra/web/constants/pages'
import useAuth from '@lyra/web/hooks/useAuth'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getPagePath } from '@lyra/web/utils/pages'
import { useRouter } from 'next/navigation'
import { XStack } from 'tamagui'

import NavAccountDropdownButton from './NavAccountDropdownButton'
import NavDevToolsDropdownButton from './NavDevToolsDropdownButton'
import NavNotificationDropdownButton from './NavNotificationDropdownButton'

export default function NavMobileHeader() {
  const { isAuthenticated } = useAuth()
  const { pendingBridges } = useTransaction()

  const router = useRouter()

  return (
    <XStack
      width="100%"
      backgroundColor="$appBg"
      zIndex={10}
      borderBottomWidth={1}
      borderColor="$hairline"
      height={NAV_MOBILE_HEIGHT}
      alignItems="center"
      padding="$3"
    >
      <XStack onPress={() => router.push(getPagePath({ page: PageId.Landing }))}>
        <Icon size={24} icon={<DeriveLogo />} />
      </XStack>
      <XStack
        marginLeft="auto"
        justifyContent="flex-end"
        alignItems="center"
        gap="$2"
        height="100%"
      >
        <NavDevToolsDropdownButton />
        {pendingBridges.length ? (
          <NextLinkButton
            label={`${pendingBridges.length} Pending`}
            href={getPagePath({ page: PageId.History, tab: HistoryTab.Deposits })}
            icon={<Spinner size={16} />}
          />
        ) : null}
        {isAuthenticated ? <NavNotificationDropdownButton /> : null}
        <NavAccountDropdownButton />
      </XStack>
    </XStack>
  )
}
