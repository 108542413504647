import { ButtonSize } from '@lyra/core/components/Button'
import Dropdown from '@lyra/core/components/Dropdown'
import { HelpCircle } from '@lyra/core/components/Icon'
import useIsMobile from '@lyra/core/hooks/useIsMobile'
import isExternalURL from '@lyra/core/utils/isExternalURL'
import { HELP_URL } from '@lyra/web/constants/urls'
import { useRouter } from 'next/navigation'
import { useMemo } from 'react'

import { getMoreTabPages } from '../../../utils/tabs'

type Props = {
  size?: ButtonSize
}

const NavMoreDropdownListItems = ({ size }: Props) => {
  const router = useRouter()
  const isMobile = useIsMobile()

  const pages = useMemo(() => {
    const pages = getMoreTabPages(isMobile)
    // prefetch all pages
    pages.forEach((page) => router.prefetch(page.path))
    return pages
  }, [router.prefetch, isMobile])

  return (
    <>
      {pages.map((page) => (
        <Dropdown.ListItem
          size={size}
          key={page.path}
          label={page.name}
          onPress={() =>
            isExternalURL(page.path) || page.isNewTab
              ? window.open(page.path, '_blank')
              : router.push(page.path)
          }
          rightIcon={page.icon ? <page.icon /> : null}
        />
      ))}
      {/* TODO: @earthtojake add feedback */}
      <Dropdown.ListItem
        label="SUPPORT"
        onPress={() => window.open(HELP_URL, '_blank')}
        rightIcon={<HelpCircle />}
        size={size}
      />
    </>
  )
}

export default NavMoreDropdownListItems
