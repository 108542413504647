const formatTruncatedEmail = (email: string, maxChars = 12, end = '...'): string => {
  const username = email.split('@')[0]

  // Truncate if username is longer than 10 characters
  if (username.length > maxChars) {
    return username.slice(0, maxChars) + end
  }

  return username
}

export default formatTruncatedEmail
