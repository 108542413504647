'use client'

import { usePathname, useRouter } from 'next/navigation'
import React, { useCallback, useMemo, useState } from 'react'

import { PasswordProtectedData } from '../constants/password'
import emptyFunction from '../utils/emptyFunction'

type Props = {
  data: PasswordProtectedData
  children?: React.ReactNode
}

export type PasswordContext = {
  isPageLocked: boolean
  isDeployAuthenticated: boolean
  isInternAuthenticated: boolean
  isInternPage: boolean
  unlock: (password: string) => Promise<boolean>
  unlockIntern: (password: string) => Promise<boolean>
}

export const PasswordContext = React.createContext<PasswordContext>({
  isPageLocked: false,
  isDeployAuthenticated: false,
  isInternAuthenticated: false,
  isInternPage: false,
  unlock: emptyFunction as any,
  unlockIntern: emptyFunction as any,
})

// Internal password unlocks depoy auth and internal pages
// Deploy auth password only unlocks deploy auth
export default function PasswordProvider({ data: initialData, children }: Props) {
  const [isDeployAuthenticated, setIsDeployAuthenticated] = useState<boolean>(
    initialData.isDeployAuthenticated
  )
  const { refresh } = useRouter()
  const pathname = usePathname()

  // Server-side only auth
  const isInternAuthenticated = initialData.isInternAuthenticated

  const unlock = useCallback(
    async (password: string) => {
      if (isDeployAuthenticated || isInternAuthenticated) {
        throw new Error('Deploy already authenticated')
      }

      const verifyRes = await fetch('/api/password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      })
      if (verifyRes.ok) {
        setIsDeployAuthenticated(true)
        refresh()
        return true
      } else {
        throw new Error('Deploy auth failed')
      }
    },
    [isDeployAuthenticated, isInternAuthenticated, refresh]
  )

  const unlockIntern = useCallback(
    async (password: string) => {
      if (isInternAuthenticated) {
        throw new Error('Intern already authenticated')
      }

      const verifyRes = await fetch('/api/password/intern', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      })
      if (verifyRes.ok) {
        // Refetch server-side
        refresh()
        return true
      } else {
        throw new Error('Deploy auth failed')
      }
    },
    [isInternAuthenticated, refresh]
  )

  const isInternPage = pathname.startsWith('/intern')
  const isPageUnlocked =
    (isInternPage && isInternAuthenticated) ||
    (!isInternPage && (isDeployAuthenticated || isInternAuthenticated))
  const isPageLocked = !isPageUnlocked

  const value = useMemo(() => {
    return {
      isDeployAuthenticated,
      isInternAuthenticated,
      isPageLocked,
      isInternPage,
      unlock,
      unlockIntern,
    }
  }, [
    isDeployAuthenticated,
    isInternAuthenticated,
    isInternPage,
    isPageLocked,
    unlock,
    unlockIntern,
  ])

  return <PasswordContext.Provider value={value}>{children}</PasswordContext.Provider>
}
