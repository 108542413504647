'use client'

import fetchValidateInviteCode from '@lyra/core/api/fetchValidateInviteCode'
import isDeepEquals from '@lyra/core/utils/isDeepEquals'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { LOCAL_STORAGE_POINTS_INVITE_CODE } from '../constants/localStorage'
import {
  LevelConfig,
  POINTS_UPDATE_INTERVAL_MS,
  PointsData,
  PointsEpoch,
} from '../constants/points'
import { baseUrl } from '../constants/urls'
import useLocalStorage from '../hooks/local_storage/useLocalStorage'
import useAuth from '../hooks/useAuth'
import useOrderbookTimestamp from '../hooks/useOrderbookTimestamp'
import emptyFunction from '../utils/emptyFunction'
import { fetchPointsData, getLevelFromPoints, getPointsEpochForTimestamp } from '../utils/points'
import { roundTimestampToLastInterval } from '../utils/time'

type Props = {
  children?: React.ReactNode
} & PointsData

export type PointsContext = {
  epoch: PointsEpoch
  level?: LevelConfig
  shareableInviteCode?: string
  shareableInviteLink?: string
  localInviteCode?: string
  lastUpdateTimestamp: number
  validateAndStoreInviteCode: (code: string) => Promise<void>
} & PointsData

const EMPTY_POINTS_DATA: PointsData = {
  isRegistered: false,
}

const EMPTY_EPOCH: PointsEpoch = {
  round: 1,
  label: '',
  startTimestamp: 0,
  endTimestamp: 0,
  levels: [
    {
      id: 1,
      cutoff: 0,
      level: 1,
      sublevel: 1,
      levelName: 'NPC',
      color: 'gray',
    },
  ],
}

export const PointsContext = React.createContext<PointsContext>({
  isRegistered: false,
  epoch: EMPTY_EPOCH,
  lastUpdateTimestamp: 0,
  validateAndStoreInviteCode: emptyFunction as any,
})

const POLL_POINTS_MS = 30_000 // 30 seconds

export default function PointsProvider({ children, ...initialPointsData }: Props) {
  const { isAuthenticated, user, inviteCode: shareableInviteCode } = useAuth()
  const address = user?.address

  const [_pointsData, setPointsData] = useState<PointsData>(initialPointsData)
  const pointsData = isAuthenticated ? _pointsData : EMPTY_POINTS_DATA

  // fallback to invite code in local storage, which we optimistically assume is verified
  const [localInviteCode, setLocalInviteCode] = useLocalStorage(LOCAL_STORAGE_POINTS_INVITE_CODE)

  const { getTimestamp } = useOrderbookTimestamp()

  const epoch = useMemo(() => getPointsEpochForTimestamp(getTimestamp()), [getTimestamp])

  const level = useMemo(
    () => (pointsData.isRegistered ? getLevelFromPoints(epoch, pointsData.totalPoints) : undefined),
    [pointsData, epoch]
  )

  const prevPoints = useRef(initialPointsData?.points)
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<number>(
    roundTimestampToLastInterval(getTimestamp(), POINTS_UPDATE_INTERVAL_MS)
  )

  const mutatePoints = useCallback(async (): Promise<PointsData> => {
    if (address) {
      const pointsData = await fetchPointsData(epoch, address)
      setPointsData(pointsData)

      const points = pointsData?.points

      if (points) {
        // update estimate for last updated points timestamp
        if (!prevPoints.current || !isDeepEquals(points, prevPoints.current)) {
          prevPoints.current = points
          setLastUpdateTimestamp(
            roundTimestampToLastInterval(getTimestamp(), POINTS_UPDATE_INTERVAL_MS)
          )
        }
      }

      return pointsData
    } else {
      return EMPTY_POINTS_DATA
    }
  }, [epoch, address, getTimestamp])

  const _registerInviteCode = useCallback(
    async (code: string) => {
      if (!isAuthenticated) {
        throw new Error('Not authenticated')
      }

      if (pointsData.isRegistered) {
        // already registered
        return
      }

      const res = await fetch('/api/register-invite-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
        }),
      })
      if (!res.ok) {
        throw new Error('Failed to register invite code')
      }

      await mutatePoints()
    },
    [pointsData.isRegistered, isAuthenticated, mutatePoints]
  )

  const registerInviteCode = useCallback(async () => {
    if (localInviteCode) {
      try {
        await _registerInviteCode(localInviteCode)
      } catch (error) {
        console.warn('failed to register with local invite code', localInviteCode)
      }
    }

    const masterInviteCode = process.env.NEXT_PUBLIC_MASTER_INVITE_CODE
    if (!masterInviteCode) {
      throw new Error('NEXT_PUBLIC_MASTER_INVITE_CODE not defined')
    }

    await _registerInviteCode(masterInviteCode)
  }, [localInviteCode, _registerInviteCode])

  // verifies an invite code and stores in local storage
  const validateAndStoreInviteCode = useCallback(
    async (code: string) => {
      try {
        // if no error is thrown, code is valid
        const { result } = await fetchValidateInviteCode(code)
        if (result !== 'ok') {
          throw new Error('invalid code')
        }
      } catch (error) {
        throw new Error('invalid code')
      }

      setLocalInviteCode(code)
    },
    [setLocalInviteCode]
  )

  // mutate points every 30 seconds
  useEffect(() => {
    const interval = setInterval(mutatePoints, POLL_POINTS_MS)
    return () => clearInterval(interval)
  }, [mutatePoints])

  useEffect(() => {
    if (isAuthenticated) {
      mutatePoints().then(async (pointsData) => {
        if (!pointsData.isRegistered) {
          console.debug('registering invite code', localInviteCode, user.address)
          await registerInviteCode()
          const points = await mutatePoints()
          if (!points.isRegistered) {
            console.warn('failed to register invite code', localInviteCode, user.address)
          }
        }
      })
    } else {
      setPointsData(EMPTY_POINTS_DATA)
    }
  }, [registerInviteCode, isAuthenticated, user?.address, mutatePoints, localInviteCode])

  const shareableInviteLink = useMemo(
    () => (shareableInviteCode ? `${baseUrl}/invite/${shareableInviteCode}` : undefined),
    [shareableInviteCode]
  )

  const value = useMemo(() => {
    return {
      ...pointsData,
      shareableInviteCode,
      shareableInviteLink,
      lastUpdateTimestamp,
      epoch,
      level,
      localInviteCode,
      validateAndStoreInviteCode,
    }
  }, [
    pointsData,
    epoch,
    level,
    lastUpdateTimestamp,
    localInviteCode,
    shareableInviteCode,
    shareableInviteLink,
    validateAndStoreInviteCode,
  ])

  return <PointsContext.Provider value={value}>{children}</PointsContext.Provider>
}
