import { StackProps } from 'tamagui'

import { MarketId } from '../constants/markets'
import { PageId } from '../constants/pages'
import {
  BLOG_DERIVE_REBRAND_URL,
  BLOG_URL,
  DISCORD_URL,
  HELP_URL,
  TWITTER_URL,
} from '../constants/urls'
import { getPagePath } from './pages'

export type MarqueeItems = {
  stackProps?: StackProps
  href?: `/${string}`
  items: MarqueeItem[]
}

export type MarqueeItem = SpotMarqueeItem | MessageMarqueeItem

export type SpotMarqueeItem = {
  id: string
  type: 'spot-price'
  marketId: MarketId
}

export type MessageMarqueeItem = {
  id: string
  type: 'message'
  messages: {
    message: string
    href?: string
    target?: string
  }[]
}

export function getBoardExpiryTimestamp(): number {
  const now = new Date()
  const targetTime = new Date(now)
  targetTime.setUTCHours(8, 0, 0, 0) // Set to 8:00 AM UTC

  if (now.getUTCHours() >= 8) {
    // If the current UTC time is past 8:00 AM, set the target for the next day
    targetTime.setUTCDate(targetTime.getUTCDate() + 1)
  }
  return targetTime.getTime()
}

export const MARQUEE_ITEMS: MarqueeItem[] = [
  {
    id: 'op-rewards',
    type: 'message',
    messages: [
      {
        message: 'Earn OP Trading Rewards',
        href: getPagePath({ page: PageId.TradersLeaderboard }),
      },
    ],
  },
  {
    id: 'lbtc-vaults',
    type: 'message',
    messages: [
      {
        message: 'Lombard Vaults Now Live',
        href: getPagePath({ page: PageId.Earn }),
      },
    ],
  },
  {
    id: 'derive-rebrand',
    type: 'message',
    messages: [
      {
        message: 'Lyra is now Derive',
        href: BLOG_DERIVE_REBRAND_URL,
        target: '_blank',
      },
    ],
  },
  {
    id: 'twitter',
    type: 'message',
    messages: [
      {
        message: 'Twitter',
        href: TWITTER_URL,
        target: '_blank',
      },
    ],
  },
  {
    id: 'discord',
    type: 'message',
    messages: [
      {
        message: 'Discord',
        href: DISCORD_URL,
        target: '_blank',
      },
    ],
  },
  {
    id: 'blog',
    type: 'message',
    messages: [
      {
        message: 'Blog',
        href: BLOG_URL,
        target: '_blank',
      },
    ],
  },
  {
    id: 'help',
    type: 'message',
    messages: [
      {
        message: 'Support',
        href: HELP_URL,
        target: '_blank',
      },
    ],
  },
  {
    id: MarketId.ETH,
    type: 'spot-price',
    marketId: MarketId.ETH,
  },
  {
    id: MarketId.BTC,
    type: 'spot-price',
    marketId: MarketId.BTC,
  },
  {
    id: MarketId.SOL,
    type: 'spot-price',
    marketId: MarketId.SOL,
  },
]
